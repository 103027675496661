import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql, StaticQuery } from 'gatsby';
import { styled } from '@mui/system';
import theme from '../theme';

import PreviewCompatibleImage from './PreviewCompatibleImage';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';

import BnWSection from './BnWSection';
import BnWContent from './BnWContent';

const Section = styled(Box)({
	display: 'flex',
	justifyContent: 'center',
	// backgroundColor: '#ffffff',
	zIndex: 1,
	top: 64,
});

const TeamCard = styled(Card)(({ theme }) => ({
	display: 'flex',
	// flexDirection: 'column',
	// justifyContent: 'center',
	position: 'relative',
	// color: theme.palette.primary.contrastText,
	// margin: theme.spacing(4),
	backgroundColor: 'initial',
	padding: theme.spacing(0),
	zIndex: 0,
	':after': {
		position: 'absolute',
		display: 'block',
		width: 'calc(100% - 16px)',
		height: 'calc(100% - 16px)',
		content: '" "',
		top: '8px',
		left: '8px',
		// border: '1px solid rgba(0, 0, 0, 0.15)',
		border: '4px solid rgba(255, 255, 255, 0.45)',
		// zIndex: -1,
		borderRadius: '5px',
	},
	'.MuiCardHeader-root': {
		// height: '220px',
		// color: theme.palette.primary.contrastText,
	},
	'.MuiCardContent-root': {
		// padding: theme.spacing(0, 4, 2),
		// paddingBottom: theme.spacing(2),
	},
	'.underColor': {
		backgroundColor: '#ffffff75',
	},
	'.filter': {
		position: 'absolute',
		backgroundColor: 'rgba(0,0,0, 0)',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		textAlign: 'center;',
		zIndex: '0',
		transition: 'all 0.5s ease',
	},
	'&:hover': {
		transition: 'all 0.5s ease',
		color: theme.palette.primary.main,
		'& .filter': {
			backgroundColor: 'rgba(255, 255, 255, 0.7)',
			transition: 'all 0.5s ease',
			transform: 'scale(1.2)',
		},
		'& .MuiButton-root, & .quals': {
			opacity: 1,
		},
		'& .MuiCardHeader-root': {
			transition: 'all 0.5s ease',
			top: '-260px',
		},
		// '& .filter': {
		// 	transition: 'all 0.5s ease',
		// 	zIndex: -1,
		// },
	},
}));

const BlogRollTemplate = (props) => {
	const { data } = props;
	const { edges: posts } = data.allMarkdownRemark;

	return (
		<BnWSection sx={{ backgroundColor: 'transparent', mx: 6 }}>
			<Grid container spacing={3} justifyContent='center'>
				{posts &&
					posts.map(({ node: post }) => (
						<Grid item xs={12} sm={6} md={3} key={post.id}>
							<TeamCard
								sx={{
									// minHeight: '400px'
									flexDirection: 'column',
								}}
							>
								{post.frontmatter.featuredimage ? (
									<Box
										sx={
											{
												// position: 'relative',
												// top: '-32px',
												// width: '100%',
												// left: '-32px',
											}
										}
									>
										<PreviewCompatibleImage
											position='relative'
											// widthOuter='200px'
											// widthInner='200px'
											imageInfo={{
												image: post.frontmatter.featuredimage,
												alt: `featured image thumbnail for post ${post.frontmatter.title}`,
											}}
										/>
									</Box>
								) : null}
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'column',
										zIndex: 1,
										position: 'absolute',
										bottom: '16px',
										width: '100%',
										alignItems: 'center',
									}}
								>
									<CardHeader
										className='header'
										sx={{
											p: 0,
											position: 'absolute',
											top: '-22px',
											transition: 'top .5s ease',
											textAlign: 'center',
											width: '80%',
										}}
										title={
											<Typography variant='h3'>
												<span className='underColor'>{post.frontmatter.title}</span>
											</Typography>
										}
										subheader={
											<Typography
												variant='span'
												className='quals'
												sx={{
													p: 0,
													opacity: 0,
													// position: 'absolute',
													transition: 'opacity .35s ease',
													width: '100%',
													ul: {
														// fontSize: '1rem',
														listStyleType: 'none',
														pl: '2px',
														lineHeight: '1.2',
														textAlign: 'center',
														li: { pb: 1, backgroundColor: '#ffffff75' },
													},
												}}
												dangerouslySetInnerHTML={{
													__html: post.frontmatter.description.html,
												}}
											/>
										}
										titleTypographyProps={{ color: theme.palette.main }}
									/>
									<CardContent
										sx={{
											p: 0,
											ul: {
												fontSize: '1rem',
												listStyleType: 'none',
												p: 0,
												lineHeight: '1.2',
												li: { pb: 1 },
											},
										}}
									>
										{/* <Typography variant='h3'>{post.frontmatter.title}</Typography> */}
										{/* <Typography variant='body1' className='description'>
											<span className='underColor'>{post.excerpt}</span>
										</Typography> */}
										{/* <Box
											dangerouslySetInnerHTML={{
												__html: post.frontmatter.description.html,
											}}
										/> */}
									</CardContent>
									<CardActions
										className='footer text-center'
										// sx={{
										// 	display: 'flex',
										// 	p: 2,
										// 	pb: 4,
										// 	justifyContent: 'center',
										// 	zIndex: 1,
										// }}
									>
										<Button
											variant='contained'
											component={Link}
											to={post.fields.slug}
											size='large'
											sx={{
												px: 2,
												py: 1,
												fontSize: '0.8rem',
												opacity: 0,
												transition: 'opacity .35s ease',
											}}
										>
											Continue Reading
										</Button>
									</CardActions>
								</Box>
								<Box className='filter'></Box>
							</TeamCard>
						</Grid>
					))}
			</Grid>
			{/* </BnWContent> */}
		</BnWSection>
	);
};

BlogRoll.propTypes = {
	data: PropTypes.shape({
		allMarkdownRemark: PropTypes.shape({
			edges: PropTypes.array,
		}),
	}),
};

export default function BlogRoll() {
	return (
		<StaticQuery
			query={graphql`
				query TeamListQuery {
					allMarkdownRemark(
						sort: { fields: [frontmatter___order], order: ASC }
						filter: { frontmatter: { templateKey: { eq: "team-member" } } }
					) {
						edges {
							node {
								excerpt(pruneLength: 400)
								id
								fields {
									slug
								}
								frontmatter {
									title
									templateKey
									order
									description {
										html
									}
									featuredimage {
										childImageSharp {
											gatsbyImageData(quality: 100, layout: FULL_WIDTH)
										}
									}
								}
							}
						}
					}
				}
			`}
			render={(data, count) => <BlogRollTemplate data={data} count={count} />}
		/>
	);
}
