import * as React from 'react';
import { styled } from '@mui/system';

import Box from '@mui/system/Box';

import Layout from '../../components/Layout';
import TeamList from '../../components/TeamList';
import { Container, Typography } from '@mui/material';
import SectionNavComponent from '../../components/Navbar-v3';
import InnerHeader from '../../components/InnerHeader';



const MainContent = styled(Box)({
	display: 'flex',
	flexDirection: 'column',
	// width: '100%',
	zIndex: 1,
});

export default class TeamIndexPage extends React.Component {
	render() {
		return (
			<Layout>
				<InnerHeader className='full-width-image-container margin-top-0'>
					<Typography variant='h1'>The Team</Typography>
				</InnerHeader>
				<SectionNavComponent sx={{ mb: 0 }} />
				<Container>
					<MainContent className='section' sx={{ pt: 4 }}>
						<TeamList gridWidth={4} />
					</MainContent>
				</Container>
			</Layout>
		);
	}
}
